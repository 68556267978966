<template>
    <div>
        <div style="display: flex;justify-content: space-between;align-items: center;padding-bottom:10px">
            <div></div>
            <el-button type="primary" size="mini" @click="showDialog" >新增课时</el-button>
        </div>
        <el-table
                :header-cell-style="{padding:0}"
                ref="singleTable"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                style="width: 100%;background: #fff;">
            <el-table-column
                    label="ID"
                    property="id"
                    width="80">
            </el-table-column>
            <el-table-column
                    label="课程名称" >
                <template slot-scope="scope">
                    <span>{{scope.row.name}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    property="index"
                    label="上课老师">
                <template slot-scope="scope">
                    <span>{{scope.row.teacher}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    property="index"
                    label="直播时间">
                <template slot-scope="scope">
                    <div>{{scope.row.start_at}}</div>
                    <div>{{scope.row.end_at}}</div>
                </template>
            </el-table-column>
            <el-table-column
                    label="班级人数" >
                <template slot-scope="scope">
                    <span>{{scope.row.seat_num}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    label="创建时间" >
                <template slot-scope="scope">
                    <span>{{scope.row.created_at}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    property="index"
                    label="创建人" >
                <template slot-scope="scope">
                    <span>{{scope.row.user_name}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    property="address"
                    label="操作">
                <template slot-scope="scope">
                            <span>
                                <el-link type="primary" @click="editCourseClass(scope.row)">编辑</el-link>
                                -
                                <el-button
                                        size="mini"
                                        type="text"
                                        @click="gotoClass(scope.row)">上课</el-button>
                                -

                                <el-Popconfirm
                                        title="确定要删除吗"
                                        @confirm="delClass(scope.row)"
                                >
                                     <el-link slot="reference" type="primary" >删除</el-link>
                            </el-Popconfirm>
                            </span>
                </template>
            </el-table-column>
        </el-table>

        <div style="padding: 10px;display: flex;justify-content: space-between;align-items: center">
            <div></div>
            <el-pagination
                    small
                    background
                    :current-page="page"
                    :page-sizes="[10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="setPageSize"
                    @current-change="setPage"
                    :total="total">
            </el-pagination>
        </div>

        <el-dialog
                title="设置课程"
                :show-close="false"
                :close-on-click-modal="false"
                append-to-body
                :visible.sync="dialogVisible"
                width="440px">
            <div>
                <el-form :rules="rules"  ref="form" :model="form" label-width="135px">
                    <el-form-item label="小节名称：" prop="name">
                        <el-input clearable size="mini" v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="上课老师：" prop="teacherUid">
                        <el-select clearable size="mini" style="width: 100%" v-model="form.teacherUid" placeholder="请选择班主任">
                            <el-option v-for="item in teachers" :key="item.id" :label="item.realname" :value="item.uid" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班级人数：" prop="seatNum">
                        <el-select clearable size="mini" style="width: 100%" v-model="form.seatNum" placeholder="请选择班主任">
                            <el-option v-for="(item,index) in 12" :key="index" :label="'1v'+item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="直播开始时间：" prop="startAt">
                        <el-date-picker
                                style="width: 100%"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                size="mini"
                                clearable
                                v-model="form.startAt"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="直播结束时间：" prop="endAt">
                        <el-date-picker
                                size="mini"
                                clearable
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                v-model="form.endAt"
                                type="datetime"
                                placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hideDialog">取 消</el-button>
            <el-button size="mini" type="primary" @click="addLiveCourse" :loading="submitLoading">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions} from "vuex";


    export default {
        name: "classList",
        data(){
            return {
                dialogVisible:false,
                submitLoading:false,
                loading:false,
                imageUrl:config.imageUrl,
                total:0,
                page:1,
                pageSize:10,
                teachers:[],
                tableData:[],
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    startAt: [
                        { required: true, message: '请选择直播开始时间', trigger: 'blur' },
                    ],
                    endAt: [
                        { required: true, message: '请选择直播结束时间', trigger: 'blur' },
                    ],
                    teacherUid: [
                        { required: true, message: '清选择上课老师', trigger: 'change' },
                    ],
                    seatNum: [
                        { required: true, message: '请选择班级人数', trigger: 'change' },
                    ]
                },
                form:{
                    id:'',
                    courseId:'',
                    name:'',
                    startAt:'',
                    endAt:'',
                    teacherUid:'',
                    seatNum:'',
                },
            }
        },
        methods:{
            ...mapActions('live',['getLiveUser','setCourseClass','getLiveCourseClassList','delCourseClass','getLoginLinked']),
            showDialog(){
                this.dialogVisible = true
                this.form.id =''
                this.form.name = ''
                this.form.startAt = ''
                this.form.endAt = ''
                this.form.teacherUid = ''
                this.form.seatNum = ''
            },
            hideDialog(){
                this.dialogVisible = false
            },
            addLiveCourse(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.submitLoading = true
                        this.setCourseClass(this.form).then(res => {
                            if(res.ret===0){
                                this.$message.success('添加成功！')
                                this.getList()
                                this.$refs['form'].resetFields()
                                this.hideDialog()
                            }
                        }).finally(() => {
                            this.submitLoading = false
                        })
                    }
                })
            },
            delClass(row){
                this.delCourseClass({id:row.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.getList()
                        this.$message.success('删除成功')
                    }
                })
            },
            gotoClass(row){
                this.getLoginLinked({
                    courseId:row.course_id,
                    classId:row.class_id,
                    uid:row.teacher_uid,
                }).then(res => {
                    if(res.ret === 0){
                        window.location.href = res.data
                    }
                    console.log(res)
                })
                console.log(row)
            },
            editCourseClass(row){
                this.showDialog()
                this.form.id = row.id
                this.form.courseId = row.course_id
                this.form.name = row.name
                this.form.startAt = row.start_at
                this.form.endAt = row.end_at
                this.form.teacherUid = row.teacher_uid
                this.form.seatNum = row.seat_num

            },
            getList(){
                this.loading = true
                let data = {
                    courseId:this.form.courseId,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getLiveCourseClassList(data).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                }).finally(() => {
                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
        },
        mounted() {
            this.getLiveUser({type:2,pageSize:2000}).then(res => {
                this.teachers = res.data.list
            })
            this.form.courseId = this.$route.params.courseId
            this.getList()
        }
    }
</script>

<style scoped>

</style>