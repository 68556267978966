<template>
    <div>
        <div style="display: flex;justify-content: space-between;align-items: center;padding-bottom:10px">
            <div></div>
            <el-button type="primary" size="mini" @click="showDialog" >添加学员</el-button>
        </div>
        <el-table
                :header-cell-style="{padding:0}"
                ref="singleTable"
                :data="tableData"
                highlight-current-row
                v-loading="loading"
                style="width: 100%;background: #fff;">
            <el-table-column
                    label="ID"
                    property="id"
                    width="80">
            </el-table-column>
            <el-table-column
                    label="用户昵称" >
                <template slot-scope="scope">
                    <span>{{scope.row.user_name}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    label="用户ID" >
                <template slot-scope="scope">
                    <span>{{scope.row.user_id}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    property="index"
                    label="classIn ID" >
                <template slot-scope="scope">
                    <span>{{scope.row.student_uid}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    label="创建时间" >
                <template slot-scope="scope">
                    <span>{{scope.row.created_at}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    property="address"
                    label="操作">
                <template slot-scope="scope">
                            <span>
                                <el-Popconfirm
                                        title="确定要删除吗"
                                        @confirm="delUser(scope.row)"
                                >
                                     <el-link slot="reference" type="primary" >删除</el-link>
                            </el-Popconfirm>
                            </span>
                </template>
            </el-table-column>
        </el-table>

        <div style="padding: 10px;display: flex;justify-content: space-between;align-items: center">
            <div></div>
            <el-pagination
                    small
                    background
                    :current-page="page"
                    :page-sizes="[10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="setPageSize"
                    @current-change="setPage"
                    :total="total">
            </el-pagination>
        </div>

        <el-dialog
                title="添加学员"
                :show-close="false"
                :close-on-click-modal="false"
                append-to-body
                :visible.sync="dialogVisible"
                width="440px">
            <div>
                <el-form :rules="rules"  ref="form" :model="form" label-width="135px">
                    <el-form-item label="学生姓名：" prop="studentUid">
                        <el-select clearable
                                   size="mini"
                                   style="width: 100%"
                                   filterable
                                   remote
                                   :remote-method="remoteMethod"
                                   v-model="form.studentUid"
                                   placeholder="请选择学生">
                            <el-option v-for="item in teachers" :key="item.id" :label="item.user_id + '：' + item.realname" :value="item.uid" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hideDialog">取 消</el-button>
            <el-button size="mini" type="primary" @click="addCourseUser" :loading="submitLoading">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "classList",
        data(){
            return {
                dialogVisible:false,
                submitLoading:false,
                loading:false,
                imageUrl:config.imageUrl,
                total:0,
                page:1,
                pageSize:10,
                teachers:[],
                tableData:[],
                rules: {
                    studentUid: [
                        { required: true, message: '清选择学员', trigger: 'change' },
                    ]
                },
                form:{
                    id:'',
                    courseId:'',
                    studentUid:'',
                },
            }
        },
        methods:{
            ...mapActions('live',['getLiveUser','addLiveCourseUser','getLiveCourseUser','delLiveCourseUser']),
            showDialog(){
                this.dialogVisible = true
            },
            hideDialog(){
                this.dialogVisible = false
            },
            addCourseUser(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.submitLoading = true
                        this.addLiveCourseUser(this.form).then(res => {
                            if(res.ret===0){
                                this.$message.success('添加成功！')
                                this.getList()
                                this.$refs['form'].resetFields()
                                this.hideDialog()
                            }
                        }).finally(() => {
                            this.submitLoading = false
                        })
                    }
                })
            },
            delUser(row){
                this.delLiveCourseUser({id:row.id}).then(res => {
                    if(res.ret === 0)
                    {
                        this.getList()
                        this.$message.success('删除成功')
                    }
                })
            },
            editCourseClass(row){
                this.form.id = row.id
                this.form.courseId = row.course_id
                this.form.name = row.name
                this.form.startAt = row.start_at
                this.form.endAt = row.end_at
                this.form.teacherUid = row.teacher_uid
                this.form.seatNum = row.seat_num
                this.showDialog()
            },
            getList(){
                this.loading = true
                let data = {
                    courseId:this.form.courseId,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getLiveCourseUser(data).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                }).finally(() => {
                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            remoteMethod(query){
                if(query !== ''){
                    this.getLiveUser({type:1,realname:query,pageSize:2000}).then(res => {
                        this.teachers = res.data.list
                    })
                }
            },
        },
        mounted() {
            this.form.courseId = this.$route.params.courseId
            this.getList()
        }
    }
</script>

<style scoped>

</style>